<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {customerService} from "@/helpers/fakebackend/customer.service";
import {userService} from "@/helpers/fakebackend/user.service";
import {globalVariable} from "@/helpers/global-variables";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Dashboard",
      loading: true,
      openWizzard: true,
      customer: {
        status: 1,
      },
      user: {
        status: 0,
      },
      registrationInfos: {
      },
      items: [
        {
          text: "Dashboard",
          active: true
        }
      ],
      globalVariable: globalVariable,
    };
  },
  async created() {
    await this.initUser();
    await this.initCustomer();
    await this.initRegistrationObject();
    this.loading = false;
  },
  methods: {
    initCustomer(){
      const user = JSON.parse(localStorage.getItem("user_infos"));
      const _customer = JSON.parse(localStorage.getItem("customer_infos"));
      if (_customer) {
        this.customer = _customer;
        return;
      }
      return  customerService.getCustomer(user.customerUuid).then((data)=>{
        this.customer = data.content;
        localStorage.setItem("customer_infos", JSON.stringify(data.content));
      });
    },
    initUser(){
      const _user = JSON.parse(localStorage.getItem("user_infos"));
      if (_user) {
        this.user = _user;
        return;
      }
      return  userService.getUser(_user.uuid).then((data)=>{
        this.user = data.content;
        localStorage.setItem("user_infos", JSON.stringify(data.content));
      });
    },
    initRegistrationObject(){
      const _registrationInfos = JSON.parse(localStorage.getItem("registration_infos"));
      if (_registrationInfos) {
        this.registrationInfos = _registrationInfos;
        return;
      }
      return  customerService.getRegistrationInfos().then((data)=>{
        this.registrationInfos = data.content;
        localStorage.setItem("registration_infos", JSON.stringify(data.content));
      });
    },
    closeWizard(){
      this.openWizzard = false;
      this.initCustomer();
    },
    openAddressPopup(){
      document.querySelector("#rx-info-bar-message")?.click();
    },
  }
};
</script>

<template>
  <b-overlay :show="loading" rounded="sm">
    <Layout>
      <PageHeader :title="title" :items="items" />
      <h2 class="mt-4 mb-4 ml-1rem">Willkommen</h2>
      <div class="row dashboard">
        <div class="col-xl-4 mb-4">
          <a :href="globalVariable.keycloak.PROFILE_URL" class="card card-gray p-3">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between mb-4">
                  <h2 class="mb-0">Profil</h2>
                  <img src="@/assets/images/icons/profil.png" class="black-icon" height="32" alt="Profil">
                  <img src="@/assets/images/icons/profil-white.png" class="white-icon" height="32" alt="profil">
                </div>
                <p>HeadlineNam, test, acient ditates mi, consequi a nonse ipsante recaectin ne nis alita
                  deliquos poribus citates tiorrov itates mincimi nvelibus</p>
              </div>
          </a>
        </div>
        <div class="col-xl-4 mb-4" v-show="user.isAccountAdmin">
          <router-link tag="a" to="/services" class="card card-gray p-3">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between mb-4">
                  <h2 class="mb-0">Leistungen</h2>
                  <img src="@/assets/images/icons/services.png" class="black-icon" height="32" alt="services">
                  <img src="@/assets/images/icons/services-white.png" class="white-icon" height="32" alt="services">
                </div>
                <p>HeadlineNam, test, acient ditates mi, consequi a nonse ipsante recaectin ne nis alita
                  deliquos poribus citates tiorrov itates mincimi nvelibus</p>
              </div>
          </router-link>
        </div>
        <div class="col-xl-4 mb-4" v-show="user.isAccountAdmin">
          <router-link tag="a" to="/users" class="card card-gray p-3">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between mb-4">
                  <h2 class="mb-0">Benutzer</h2>
                  <img src="@/assets/images/icons/user.png" class="black-icon" height="32" alt="user">
                  <img src="@/assets/images/icons/user-white.png" class="white-icon" height="32" alt="user">
                </div>
                <p>HeadlineNam, test, acient ditates mi, consequi a nonse ipsante recaectin ne nis alita
                  deliquos poribus citates tiorrov itates mincimi nvelibus</p>
              </div>
          </router-link>
        </div>
        <div class="col-xl-4 mb-4" v-show="user.isAccountAdmin">
          <router-link tag="a" to="/base-data" class="card card-gray p-3">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between mb-4">
                  <h2 class="mb-0">Stammdaten</h2>
                  <img src="@/assets/images/icons/user-data.png" class="black-icon" height="32" alt="user data">
                  <img src="@/assets/images/icons/user-data-white.png" class="white-icon" height="32" alt="user data">
                </div>
                <p>HeadlineNam, test, acient ditates mi, consequi a nonse ipsante recaectin ne nis alita
                  deliquos poribus citates tiorrov itates mincimi nvelibus</p>
              </div>
          </router-link>
        </div>
        <div class="col-xl-4 mb-4" v-show="user.isAccountAdmin">
          <router-link tag="a" to="/billings" class="card card-gray p-3">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between mb-4">
                  <h2 class="mb-0">Rechnungen</h2>
                  <img src="@/assets/images/icons/billing.png" class="black-icon" height="32" alt="billing">
                  <img src="@/assets/images/icons/billing-white.png" class="white-icon" height="32" alt="billing">
                </div>
                <p>HeadlineNam, test, acient ditates mi, consequi a nonse ipsante recaectin ne nis alita
                  deliquos poribus citates tiorrov itates mincimi nvelibus</p>
              </div>
          </router-link>
        </div>
        <div class="col-xl-4 mb-4">
          <a href="http://kyberio-dashboard.dev.ky9s.io/" target="_blank" class="card card-outline p-3">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between mb-4">
                  <h2 class="mb-0">Cloud</h2>
                  <img src="@/assets/images/icons/cloud.png" class="black-icon" height="32" alt="">
                  <img src="@/assets/images/icons/cloud-white.png" class="white-icon" height="32" alt="">
                </div>
                <p>Hier geht es direkt zum externen Verwaltungsinterface</p>
              </div>
          </a>
        </div>
      </div>
<!--
      <complete-profile v-if="!loading && openWizzard && (customer.status === 3 || openWizzard)" v-on:close="closeWizard" :registration-infos="registrationInfos"></complete-profile>
-->
    </Layout>
  </b-overlay>
</template>